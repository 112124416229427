import { createClient, fetchExchange, subscriptionExchange, Provider, cacheExchange, mapExchange } from 'urql';
import { retryExchange, RetryExchangeOptions } from '@urql/exchange-retry';
import { authExchange } from '@urql/exchange-auth';
import { PropsWithChildren } from 'react';
import * as withAbsintheSocket from '@absinthe/socket';
import { Socket as PhoenixSocket } from 'phoenix';
// import { offlineExchange } from '@urql/exchange-graphcache';
// import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';

// import schema from './introspection.json';

// const storage = makeDefaultStorage({
// 	idbName: 'graphcache-v3', // The name of the IndexedDB database
// 	maxAge: 1 / 6, // The maximum age of the persisted data in days
// });

// const cacheExchange = offlineExchange({
// 	schema,
// 	storage,
// 	isOfflineError: (error) => Boolean(error?.networkError),

// 	optimistic: {
// 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
// 		createScans: (_args, _cache, _info) => null,
// 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
// 		deleteScan: (_args, _cache, _info) => null,
// 	}
// });

const retryOptions: RetryExchangeOptions = {
	maxNumberAttempts: 3,
};

const origin = import.meta.env.API_ORIGIN || window.location.origin;
const wsOrigin = origin.replace(/^http/gi, 'ws');
const phoenixSocket = new PhoenixSocket(`${wsOrigin}/socket`);
const absintheSocket = withAbsintheSocket.create(phoenixSocket);

enum ConnectionStatus {
	Online = 'online',
	Offline = 'offline',
}
export const connectionStatus = new EventTarget();
let currentStatus = ConnectionStatus.Online;

// connectionStatus.addEventListener(ConnectionStatus.Online, () => console.log('Online!'));
// connectionStatus.addEventListener(ConnectionStatus.Offline, () => console.log('Offline!'));

export const gqlClient = createClient({
	url: `${origin}/gql`,
	fetchOptions: {
		credentials: 'same-origin',
	},
	exchanges: [
		cacheExchange,
		mapExchange({
			onResult(result) {
				if (!result.error?.networkError && currentStatus === ConnectionStatus.Offline) {
					connectionStatus.dispatchEvent(new Event(ConnectionStatus.Online));
					currentStatus = ConnectionStatus.Online;
				}
			},
			onError(error) {
				if (error.networkError && currentStatus === ConnectionStatus.Online) {
					connectionStatus.dispatchEvent(new Event(ConnectionStatus.Offline));
					currentStatus = ConnectionStatus.Offline;
				}
			}
		}),
		retryExchange(retryOptions),
		authExchange(async () => ({
			addAuthToOperation: (o) => o,
			willAuthError: () => false,
			didAuthError: (error) => error?.response?.status === 401,
			refreshAuth: async () => {
				if (window.location.pathname !== '/login') {
					window.location.pathname = '/logout';
				}
			},
		})),
		fetchExchange,
		subscriptionExchange({
			// @ts-ignore: the types for the absinthe/pheonix stuff arent very good
			forwardSubscription: (operation) => {
				const notifier = withAbsintheSocket.send(absintheSocket, {
					// @ts-ignore: the types for the absinthe/pheonix stuff arent very good
					operation: operation.query,
					variables: operation.variables,
				});
				// @ts-ignore
				return withAbsintheSocket.toObservable(absintheSocket, notifier, {});
			},
		})
	]
});

export const GQLProvider = ({ children }: PropsWithChildren<{}>) =>
	<Provider value={gqlClient}>{children}</Provider>;
