//@ts-nocheck
import * as Types from '../../gql-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MeFragment = { readonly __typename?: 'User', readonly id: string, readonly email: string, readonly org?: { readonly __typename?: 'Org', readonly id: string, readonly name: string, readonly usesDefaultRecipes: boolean, readonly usesScanners: boolean, readonly usesNfc: boolean, readonly usesGps: boolean, readonly usesDiscardLocations: boolean } | null };

export type OrgQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgQueryQuery = { readonly __typename?: 'RootQueryType', readonly me?: { readonly __typename?: 'User', readonly id: string, readonly email: string, readonly org?: { readonly __typename?: 'Org', readonly id: string, readonly name: string, readonly usesDefaultRecipes: boolean, readonly usesScanners: boolean, readonly usesNfc: boolean, readonly usesGps: boolean, readonly usesDiscardLocations: boolean } | null } | null };

export const MeFragmentDoc = gql`
    fragment Me on User {
  id
  email
  org {
    id
    name
    usesDefaultRecipes
    usesScanners
    usesNfc
    usesGps
    usesDiscardLocations
  }
}
    `;
export const OrgQueryDocument = gql`
    query OrgQuery {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

export function useOrgQueryQuery(options?: Omit<Urql.UseQueryArgs<OrgQueryQueryVariables>, 'query'>) {
  return Urql.useQuery<OrgQueryQuery, OrgQueryQueryVariables>({ query: OrgQueryDocument, ...options });
};