import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { GQLProvider } from './gql-client';
import { App } from './App';
import { IsMobileProvider } from './utils/useIsMobile';
import { StorageProvider } from './utils/useLocalStorage';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { ThemeProvider } from 'shared';
import React from 'react';
import './index.css';

const container = document.getElementById('root');

if (window.location.protocol !== 'https:') {
	window.location.protocol = 'https:';
}

if (!container) {
	throw new Error('Root element not present! Please contact engineering!');
}

if (import.meta.env.USE_SENTRY && JSON.parse(import.meta.env.USE_SENTRY)) {
	Sentry.init({
		dsn: 'https://aa44f0872f2d4d5d9f98f7fab09c663c@o4504645495357440.ingest.sentry.io/4504657879498752',
		tracesSampleRate: 1.0,
		integrations: [new BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		})],
		release: `sbt-ui-${import.meta.env.VERSION}`,
	});
}

const root = createRoot(container);
root.render(
	<ErrorBoundary>
		<GQLProvider>
			<ThemeProvider>
				<IsMobileProvider>
					<StorageProvider>
						<App />
					</StorageProvider>
				</IsMobileProvider>
			</ThemeProvider>
		</GQLProvider>
	</ErrorBoundary>,
);
