import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useOrg } from './useOrg/useOrg';
import { Button } from 'antd';
import './useNFC.scss';

const NFCContext = React.createContext<boolean>(false);

export const NDEFProvider = ({ children }: PropsWithChildren) => {
	const [showButton, setShowButton] = useState(false);
	const [hasPermission, setHasPermission] = useState(false);
	const { org } = useOrg();

	const promptForPermissionsGrant = () => {
		// Trigger a permissions prompt in the browser
		const ndef = new NDEFReader();
		const controller = new AbortController();
		ndef.scan({ signal: controller.signal });
		controller.abort();
	};

	useEffect(() => {
		if (org.usesNfc) {
			navigator.permissions.query({ name: 'nfc' as PermissionName })
				.then((nfcStatus) => {
					if (nfcStatus.state === 'granted') {
						setShowButton(false);
						setHasPermission(true);
					} else {
						setShowButton(true);
					}

					nfcStatus.onchange = () => {
						setShowButton(false);
						setHasPermission(true);
						toast.success('NFC enabled');
					};
				});
		}
	}, [org.usesNfc]);

	return (
		<NFCContext.Provider value={hasPermission}>
			{children}
			{org.usesNfc && showButton && <div className="nfc-grant-button-container">
				<Button onClick={promptForPermissionsGrant} >Grant NFC Permissions</Button>
			</div>}
		</NFCContext.Provider>
	);
};

type Reader = (ndef: NDEFReader, event: NDEFReadingEvent) => void;
export const useNFCRead = (onRead: Reader, deps?: React.DependencyList) => {
	const hasPermission = useContext(NFCContext);
	const dependencyList = (deps ?? []).concat([hasPermission]);

	useEffect(() => {
		if (hasPermission) {
			const ndef = new NDEFReader();
			ndef.onreading = (event) => onRead(ndef, event);
			const controller = new AbortController();
			ndef.scan({ signal: controller.signal });
			return () => { controller.abort(); };
		}
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => { };
	}, dependencyList);
};

export const useNDEF = () => {
	const hasPermission = useContext(NFCContext);
	return useMemo(() => (hasPermission ? new NDEFReader() : undefined), [hasPermission]);
};

export const useNFCWrite = () => {
	const ndef = useNDEF();

	return useCallback((value: NDEFMessageSource, options?: NDEFWriteOptions) => {
		if (!ndef) {
			toast.error('Insufficent NFC permissions!');
			return Promise.reject();
		}

		return ndef.write(value, options);
	}, [ndef]);
};

export const useNFCReadAsync = () => {
	const ndef = useNDEF();

	return useCallback((controller = new AbortController()) => {
		if (!ndef) {
			toast.error('Insufficent NFC permissions!');
			return Promise.reject();
		}

		return new Promise<NDEFReadingEvent>((resolve, reject) => {
			ndef.onreading = (event) => {
				resolve(event);
			};
			ndef.onreadingerror = (error) => {
				reject(error);
			};
			ndef.scan({ signal: controller.signal });
		});
	}, [ndef]);
};

export class NFCEntityError extends Error { }

export const useReadEntityTag = () => {
	const read = useNFCReadAsync();

	return useCallback(async (controller = new AbortController()) => {
		const event = await read(controller);
		const record = event.message.records[0];
		if (!record) {
			throw new NFCEntityError('Not a valid entity tag!');
		}

		if (!record.data || !record.id) {
			throw new NFCEntityError('Not a valid entity tag!');
		}

		const decoder = new TextDecoder(record.encoding ?? 'utf-8');

		return {
			id: record.id,
			code: decoder.decode(record.data),
		};
	}, [read]);
};

export const useWriteEntityTag = () => {
	const write = useNFCWrite();

	return useCallback(({ id, code }: { id: string, code: string }) => write({
		records: [{
			id,
			data: code,
			recordType: 'text'
		}]
	}, {
		overwrite: true,
	}), [write]);
};
