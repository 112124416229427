import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { noop } from 'lodash';

import '../useNFC.scss';
import { useOrg } from '../useOrg/useOrg';
import { Button } from 'antd';

const GPSContext = React.createContext<boolean>(false);

export const GeolocationProvider = ({ children }: PropsWithChildren) => {
	const [showButton, setShowButton] = useState(false);
	const [hasPermission, setHasPermission] = useState(false);

	const { org } = useOrg();

	const promptForPermissionsGrant = () => {
		navigator.geolocation.getCurrentPosition(noop);
	};

	useEffect(() => {
		if (org.usesGps) {
			navigator.permissions.query({ name: 'geolocation' })
				.then((geoStatus) => {
					if (geoStatus.state === 'granted') {
						setShowButton(false);
						setHasPermission(true);
					} else {
						setShowButton(true);
					}

					geoStatus.onchange = () => {
						setShowButton(false);
						setHasPermission(true);
						toast.success('GPS enabled');
					};
				});
		}
	}, [org.usesGps]);

	return (
		<GPSContext.Provider value={org.usesGps && hasPermission}>
			{children}
			{org.usesGps && showButton && <div className="nfc-grant-button-container">
				<Button onClick={promptForPermissionsGrant}>Grant Geolocation Permissions</Button>
			</div>}
		</GPSContext.Provider>
	);
};

const defaultOptions: PositionOptions = { enableHighAccuracy: true, maximumAge: 1000 };

export const useGPS = () => {
	const hasPermission = useContext(GPSContext);
	return useCallback(() => {
		if (hasPermission) {
			return new Promise<GeolocationCoordinates>((resolve, reject) => {
				navigator.geolocation.getCurrentPosition((position) => {
					resolve(position.coords);
				}, (error) => {
					reject(error);
				}, defaultOptions);
			});
		}
		return Promise.reject(new Error('You have not yet granted geolocation permissions!'));
	}, [hasPermission]);
};

export const useGPSCoords = () => {
	const hasPermission = useContext(GPSContext);
	const [position, setPosition] = useState<GeolocationCoordinates>();
	useEffect(() => {
		if (hasPermission) {
			const watch = navigator.geolocation.watchPosition(({ coords }) => {
				setPosition(coords);
			}, undefined, defaultOptions);
			return () => navigator.geolocation.clearWatch(watch);
		}
	}, [hasPermission]);

	return position;
};
