import React, { useCallback, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Typography } from 'antd';
import { CheckOutlined, CopyOutlined, EyeOutlined } from '@ant-design/icons';
const { Text, Title } = Typography;

export const ErrorBoundary = ({ children }: React.PropsWithChildren) => {
	const [error, setError] = useState<Error>();
	const [errorInfo, setErrorInfo] = useState<string>();
	const [errorCopied, setErrorCopied] = useState(false);
	const [errorShown, setErrorShown] = useState(false);

	const onError = (e: Error, componentStack: string) => {
		setError(e);
		setErrorInfo(componentStack);
	};

	const copyErrorLog = useCallback((errorMessage: string) => {
		navigator.clipboard.writeText(errorMessage);
		setErrorCopied(true);
	}, []);

	const fallback = useMemo(() => {
		const errorString = error ? error.toString() : '';
		const errorMessage = `${errorString}${errorInfo}`;
		return (
			<div className="error-boundary">
				<div className="message">
					<Title className="title">Unable to load page</Title>

					<Text className="sub-title">
						Something unexpected happened on our end while loading this page.
						Try refreshing, or copy the error details and let the engineering team know on Slack.
					</Text>
				</div>
				<Button
					color="blue"
					icon={<EyeOutlined />}
					onClick={() => setErrorShown((e) => !e)}
				>{errorShown ? 'Hide Error Details' : 'Show Error Details'}</Button>
				{errorShown &&
					<div className="error-stacktrace">
						<textarea
							readOnly
							onClick={() => copyErrorLog(errorMessage)}
							rows={10}
						>
							{errorMessage}
						</textarea>
						<Button
							color="blue"
							icon={errorCopied ? <CheckOutlined /> : <CopyOutlined />}
							onClick={() => copyErrorLog(errorMessage)}
						>{errorCopied ? 'Copied to Clipboard' : 'Copy to Clipboard'}</Button>
					</div>}
			</div>
		);
	}, [copyErrorLog, error, errorInfo, errorShown]);

	return (
		<Sentry.ErrorBoundary onError={onError} fallback={fallback}>
			{children}
		</Sentry.ErrorBoundary>
	);
};
