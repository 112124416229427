import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { APP_NAME } from '../constants';

// eslint-disable-next-line no-console
console.log(`${APP_NAME} Build version: ${import.meta.env.VERSION}`);

export const useUpgradeReload = () => {
	const { pathname } = useLocation();
	const [shouldReload, setShouldReload] = useState(false);

	useEffect(() => {
		if (shouldReload) {
			window.location.reload();
			return;
		}

		if (import.meta.env.VERSION && import.meta.env.VERSION !== 'dev') {
			fetch('/version.json')
				.then((response) => response.json()
					.then((body) => {
						if (import.meta.env.VERSION && body.version !== import.meta.env.VERSION) {
							setShouldReload(true);
						}
					})).catch((e) => Sentry.captureException(e));
		}
	}, [pathname]);
};
