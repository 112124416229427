import { useEffect, useState, createContext, PropsWithChildren, useContext } from 'react';

const deriveIsMobile = () => window.innerWidth <= 600;

const context = createContext(deriveIsMobile());

export const IsMobileProvider = ({ children }: PropsWithChildren) => {
	const [isMobile, setIsMobile] = useState(deriveIsMobile);

	useEffect(() => {
		const onResize = () => {
			setIsMobile(deriveIsMobile());
		};
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	return <context.Provider value={isMobile}>{children}</context.Provider>;
};

export const useIsMobile = () => useContext(context);
