import React, { PropsWithChildren, useContext } from 'react';

import { MeFragment, useOrgQueryQuery } from './user-org.generated';
import { Spin } from 'antd';
import { MandateProps, withProperty } from 'shared';

const orgContext = React.createContext<MandateProps<MeFragment, 'org'>>({
	id: '',
	email: '',
	org: {
		id: '',
		name: '',
		usesDefaultRecipes: false,
		usesDiscardLocations: false,
		usesNfc: false,
		usesGps: false,
		usesScanners: false,
	}
});

export const useOrg = () => useContext(orgContext);

export const OrgContext = ({ children }: PropsWithChildren) => {
	const [{ data, fetching, error }] = useOrgQueryQuery({
		requestPolicy: 'network-only',
	});

	if (error) throw error;

	if (fetching || !data?.me) {
		return <Spin />;
	}

	const { me } = data;
	if (!withProperty(me, 'org')) {
		throw new Error(`${me.email} not tied to an organization!`);
	}

	return <orgContext.Provider value={me}>{children}</orgContext.Provider>;
};
