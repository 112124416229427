//@ts-nocheck
import * as Types from '../../gql-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMeQuery = { readonly __typename?: 'RootQueryType', readonly me?: { readonly __typename?: 'User', readonly id: string, readonly email: string, readonly org?: { readonly __typename?: 'Org', readonly id: string, readonly name: string, readonly usesSbt: boolean, readonly usesCore: boolean } | null } | null };


export const GetMeDocument = gql`
    query GetMe {
  me {
    id
    email
    org {
      id
      name
      usesSbt
      usesCore
    }
  }
}
    `;

export function useGetMeQuery(options?: Omit<Urql.UseQueryArgs<GetMeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeQuery, GetMeQueryVariables>({ query: GetMeDocument, ...options });
};