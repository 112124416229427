import { isNil, mapValues, noop } from 'lodash';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { FilterState } from '../pages/stage/filters';

type StorageTypes = {
	filterState: FilterState;
};
type RequireAllKeys<T> = {
	[K in keyof T]-?: T[K] | null;
}
type Storage = RequireAllKeys<StorageTypes>;

const defaultStorageValues: Storage = {
	filterState: null,
};

type StorageContext = {
	values: Storage;
	setValue: <K extends keyof Storage>(key: K, value: Storage[K]) => void;
}

const storageContext = createContext<StorageContext>({
	values: defaultStorageValues,
	setValue: noop,
});

export const StorageProvider = ({ children }: PropsWithChildren<{}>) => {
	const [values, setValues] = useState<Storage>(defaultStorageValues);
	useEffect(() => {
		setValues(mapValues(defaultStorageValues, (value, key) => {
			try {
				const stringVal = localStorage.getItem(key);
				return stringVal ? JSON.parse(stringVal) : value;
			} catch (e) {
				return value;
			}
		}));
	}, []);

	const setValue: StorageContext['setValue'] = useCallback((key, value) => {
		if (isNil(value)) {
			localStorage.removeItem(key);
		} else {
			localStorage.setItem(key, JSON.stringify(value));
		}
		setValues((currentValues) => ({
			...currentValues,
			[key]: value
		}));
	}, []);

	return <storageContext.Provider value={{ values, setValue }}>{children}</storageContext.Provider>;
};

export const useStorage = <Key extends keyof Storage>(key: Key) => {
	const { values, setValue } = useContext(storageContext);
	const set = useCallback(
		(value: Storage[Key]) => setValue(key, value),
		[key, setValue]
	);
	return [values[key], set] as const;
};
